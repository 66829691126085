import React, { useEffect, useState } from 'react';
import { BackWrapper, Container, Content, EmptyDiv, Header, StyledButton } from './UploadPhotos.styles';
import { ReactComponent as BackIcon } from '../../assets/icons/chevron_left.svg';
import { Text16Regular } from '../utils/Text/Text.styles';
import { UPLOAD_FILE_CONSTRAINTS } from '../../utils/constants';
import { notifyError, notifyInfo } from '../../utils/notify';
import { useDispatch } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';
import UploadMediaButton from '../UploadMediaButton/UploadMediaButton';
import MediaComponent from '../MediaComponent/MediaComponent';
import { updateNumOfUpdatedMedia } from '../../store/slices/user/slice';
import { uploadPhoto } from '../../store/slices/influencerRequest/asyncThunks';

const UploadPhotos = ({ onBack }) => {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState([]);
  const [uploadingProgress, setUploadingProgress] = useState([]);
  const [loading, setLoading] = useState(false);

  const myUploadProgress = index => progress => {
    let percentage = Math.floor((progress.loaded * 100) / progress.total);
    setUploadingProgress(prev => {
      const newProgress = [...prev];
      newProgress[index] = percentage > 99 ? 99 : percentage;
      return newProgress;
    });
  };

  useEffect(() => {
    if (uploadingProgress.length && !uploadingProgress.filter(progress => progress !== 100).length) {
      dispatch(
        updateNumOfUpdatedMedia({ numOfMedia: uploadingProgress.length, type: UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO }),
      );
      setLoading(false);
      onBack();
      notifyInfo('Photos uploaded successfully');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingProgress]);

  const onUploadClick = () => {
    setLoading(true);
    for (const [index, photo] of photos.entries()) {
      dispatch(
        uploadPhoto({
          photo,
          onUploadProgress: myUploadProgress(index),
        }),
      )
        .unwrap()
        .then(() => {
          setUploadingProgress(prev => {
            const newProgress = [...prev];
            newProgress[index] = 100;
            return newProgress;
          });
        })
        .catch(err => {
          setLoading(false);
          notifyError(err.message);
        });
    }
  };

  return (
    <Container>
      <Header>
        <BackWrapper onClick={onBack}>
          <BackIcon />
        </BackWrapper>
        <Text16Regular>Photos</Text16Regular>
        <EmptyDiv />
      </Header>
      <Content>
        <UploadMediaButton
          type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
          onChange={files => setPhotos([...photos, ...files])}
        />
        {photos.map((photo, index) => {
          return (
            <MediaComponent
              key={photo.file.name}
              type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
              file={photo}
              progress={uploadingProgress[index]}
              onDelete={() => setPhotos(prev => prev.filter((_, ind) => index !== ind))}
            />
          );
        })}
      </Content>
      <StyledButton title="Upload" onClick={onUploadClick} isLoading={loading} />
    </Container>
  );
};

export default UploadPhotos;
