import styled from 'styled-components';
import { Text13Light, Text14Light } from '../utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  gap: 4.4rem;

  @media only screen and (max-width: 630px) {
    gap: 1.2rem;
    margin-top: 1rem;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
`;

export const StepCircle = styled.div`
  border-radius: 50%;
  background: ${({ $active }) => ($active ? 'linear-gradient(180deg, #AB75FF 0%, #4D2595 100%)' : '#2c1655')};
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CircleText = styled(Text14Light)`
  font-weight: ${({ $active }) => $active && '600'};
  color: ${({ $active }) => ($active ? '#FFF' : '#C99AFF')};
`;

export const StepText = styled(Text13Light)`
  font-weight: ${({ $active }) => $active && '500'};
  color: ${({ $active }) => ($active ? '#FFF' : '#A080DB')};
  letter-spacing: -0.408px;
  text-align: center;
`;
