import React from 'react';
import {
  LogoWrapper,
  NavLinkIconWrapper,
  NavLinkText,
  NavigationContainer,
  NavigationItem,
  NavigationItems,
  NavigationWrapper,
} from './Navigation.styles';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { NavLink } from 'react-router-dom';
import { HOME, PROFILE } from '../App/routes';
import LogoComponent from '../LogoComponent/LogoComponent';

const Navigation = () => {
  return (
    <NavigationWrapper>
      <NavigationContainer>
        <NavigationItems>
          <LogoWrapper>
            <LogoComponent />
          </LogoWrapper>
          <NavigationItem>
            <NavLink to={HOME}>
              <NavLinkIconWrapper>
                <HomeIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Home</NavLinkText>
            </NavLink>
          </NavigationItem>

          <NavigationItem>
            <NavLink to={PROFILE}>
              <NavLinkIconWrapper>
                <UserIcon />
              </NavLinkIconWrapper>
              <NavLinkText>Profile</NavLinkText>
            </NavLink>
          </NavigationItem>
        </NavigationItems>
      </NavigationContainer>
      {/* <NavigationFooter>
        <FooterButton>
          <MessagePlusIcon />
          <Text14Light>Support</Text14Light>
        </FooterButton>
      </NavigationFooter> */}
    </NavigationWrapper>
  );
};

export default Navigation;
