import React from 'react';

const LogoComponent = () => {
  return (
    <svg width="160" height="39" viewBox="0 0 160 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.68019 33.9756C2.28104 32.3831 1.28253 30.6039 0.651633 28.5999C0.0074823 26.5539 -0.138768 24.4717 0.123411 22.3681C0.432814 19.8856 1.35385 17.6255 2.85555 15.6116C2.86263 15.6021 2.87027 15.5928 2.8789 15.5847C3.45219 15.0481 3.55708 14.4364 3.43449 13.6459C3.15432 11.8392 3.03886 10.0104 3.19432 8.1781C3.21623 7.91978 3.27259 7.66254 3.33366 7.40979C3.43601 6.98618 3.66074 6.8187 4.06367 6.97672C4.81151 7.27002 5.58068 7.55342 6.25638 7.97587C7.40104 8.69152 8.47493 9.5194 9.59292 10.279C9.73258 10.3739 9.98096 10.4084 10.1424 10.3568C11.7213 9.852 13.3372 9.63028 14.9913 9.66667C16.4025 9.69771 17.7842 9.91089 19.1211 10.3715C19.3796 10.4606 19.557 10.4067 19.7661 10.2366C21.2065 9.06438 22.7273 8.01193 24.424 7.23648C24.6706 7.1238 24.9351 7.04406 25.1981 6.97495C25.6478 6.8568 25.8944 6.97639 25.945 7.42552C26.0498 8.35388 26.1662 9.29011 26.1479 10.2206C26.1294 11.1654 26.0248 12.1215 25.835 13.0476C25.2405 15.9481 24.0684 18.5727 21.9469 20.7083C21.6659 20.9911 21.6067 20.9829 21.4196 20.6254C19.8027 17.5365 16.0687 15.9147 12.6861 16.85C10.228 17.5297 8.49403 19.0464 7.50964 21.404C5.89387 25.2736 7.80364 29.8328 11.7311 31.4043C13.3818 32.0648 15.0506 32.1354 16.7654 31.7284C18.457 31.327 19.7871 30.3346 21.0266 29.1856C22.148 28.1461 23.1882 27.0079 24.5483 26.2569C26.118 25.3903 27.7688 24.9185 29.5786 25.282C31.5421 25.6764 32.9301 26.8255 33.75 28.615C34.0861 29.3486 34.2279 30.1711 34.4557 30.9538C34.4678 30.9955 34.4572 31.0437 34.4572 31.1576C34.1285 31.065 33.8304 30.9812 33.5324 30.897C31.3471 30.2802 29.3812 30.7511 27.6606 32.1768C26.8224 32.8715 26.083 33.6849 25.2973 34.443C23.7251 35.9602 21.9504 37.1748 19.9011 37.9532C17.1868 38.9843 14.4016 39.1839 11.5603 38.5767C8.43685 37.9092 5.8251 36.3657 3.68019 33.9756Z"
        fill="url(#paint0_linear_103_22516)"
      />
      <path
        d="M142.878 31.7871C141.734 31.7871 140.758 31.5736 139.95 31.1467C139.142 30.7198 138.51 30.0871 138.052 29.2486L140.248 28.0136C140.522 28.5015 140.858 28.8522 141.254 29.0656C141.65 29.2791 142.146 29.3858 142.74 29.3858C143.121 29.3858 143.449 29.34 143.724 29.2486C143.998 29.1418 144.212 29.0046 144.364 28.8369C144.532 28.654 144.616 28.4405 144.616 28.1966C144.616 27.8612 144.463 27.6096 144.158 27.4419C143.853 27.2742 143.465 27.1446 142.992 27.0531C142.519 26.9464 142.009 26.8397 141.46 26.7329C140.926 26.611 140.423 26.4356 139.95 26.2069C139.478 25.9783 139.089 25.6505 138.784 25.2236C138.479 24.7967 138.327 24.2249 138.327 23.5084C138.327 22.8223 138.51 22.2124 138.876 21.6788C139.257 21.1452 139.783 20.7183 140.454 20.3981C141.124 20.078 141.902 19.9179 142.786 19.9179C143.579 19.9179 144.265 20.0322 144.844 20.2609C145.424 20.4744 145.911 20.7641 146.308 21.13C146.704 21.4806 147.017 21.877 147.245 22.3192L145.05 23.577C144.852 23.1348 144.555 22.8147 144.158 22.6165C143.762 22.4183 143.327 22.3192 142.855 22.3192C142.565 22.3192 142.298 22.3649 142.054 22.4564C141.826 22.5479 141.643 22.6775 141.505 22.8452C141.368 23.0129 141.3 23.2111 141.3 23.4398C141.3 23.7599 141.452 24.0039 141.757 24.1716C142.062 24.3393 142.451 24.4765 142.923 24.5832C143.396 24.69 143.899 24.8043 144.433 24.9263C144.966 25.0482 145.469 25.2236 145.942 25.4523C146.415 25.681 146.803 26.0087 147.108 26.4356C147.413 26.8473 147.566 27.4114 147.566 28.128C147.566 28.8598 147.352 29.5001 146.925 30.049C146.498 30.5979 145.927 31.0247 145.21 31.3297C144.494 31.6346 143.716 31.7871 142.878 31.7871Z"
        fill="white"
      />
      <path
        d="M134.973 31.8092C133.937 31.8092 133.068 31.611 132.366 31.2146C131.665 30.8182 131.139 30.2694 130.788 29.568C130.438 28.8667 130.262 28.0587 130.262 27.1439V17.3101H133.212V27.0524C133.212 27.7232 133.395 28.2416 133.761 28.6075C134.142 28.9582 134.638 29.1335 135.248 29.1335C135.583 29.1335 135.941 29.0878 136.323 28.9963C136.704 28.8896 137.031 28.7447 137.306 28.5618V31.2375C137.016 31.4052 136.658 31.5424 136.231 31.6491C135.804 31.7559 135.385 31.8092 134.973 31.8092ZM137.306 22.7072H128.136V20.1916H137.306V22.7072Z"
        fill="white"
      />
      <path
        d="M120.996 31.7869C119.898 31.7869 118.93 31.5201 118.091 30.9865C117.253 30.4376 116.59 29.7134 116.102 28.8139C115.614 27.9144 115.37 26.9157 115.37 25.818C115.37 24.7355 115.637 23.7445 116.17 22.845C116.719 21.9455 117.451 21.2289 118.366 20.6953C119.281 20.1617 120.31 19.8949 121.453 19.8949C122.627 19.8949 123.671 20.1541 124.586 20.6724C125.501 21.1908 126.225 21.8997 126.759 22.7993C127.292 23.6835 127.559 24.6898 127.559 25.818V31.4896H124.746V27.3731L125.661 27.0072C125.661 27.9067 125.463 28.7224 125.066 29.4542C124.685 30.1708 124.144 30.7425 123.443 31.1694C122.757 31.5811 121.941 31.7869 120.996 31.7869ZM121.453 28.9968C122.063 28.9968 122.604 28.852 123.077 28.5623C123.549 28.2727 123.915 27.8915 124.174 27.4189C124.449 26.9462 124.586 26.4202 124.586 25.8409C124.586 25.2615 124.449 24.7355 124.174 24.2629C123.915 23.775 123.549 23.3939 123.077 23.1194C122.604 22.8298 122.063 22.6849 121.453 22.6849C120.859 22.6849 120.325 22.8298 119.852 23.1194C119.38 23.3939 119.006 23.775 118.732 24.2629C118.473 24.7355 118.343 25.2615 118.343 25.8409C118.343 26.4202 118.473 26.9462 118.732 27.4189C119.006 27.8915 119.38 28.2727 119.852 28.5623C120.325 28.852 120.859 28.9968 121.453 28.9968Z"
        fill="white"
      />
      <path
        d="M105.587 31.4892H102.637V15.0005H105.587V23.485H104.238C104.284 22.8447 104.52 22.2501 104.947 21.7012C105.374 21.1523 105.938 20.7178 106.639 20.3976C107.34 20.0622 108.11 19.8945 108.949 19.8945C110.092 19.8945 111.053 20.1308 111.83 20.6035C112.608 21.0609 113.195 21.7164 113.591 22.5702C113.988 23.424 114.186 24.4379 114.186 25.6118V31.4892L111.213 31.4664V25.9091C111.213 25.3298 111.114 24.7962 110.916 24.3083C110.717 23.8204 110.412 23.4316 110.001 23.142C109.589 22.837 109.086 22.6846 108.491 22.6846C107.927 22.6846 107.424 22.8294 106.982 23.1191C106.54 23.3935 106.197 23.7823 105.953 24.2854C105.709 24.7733 105.587 25.3298 105.587 25.9549V31.4892Z"
        fill="white"
      />
      <path
        d="M95.1334 31.7868C94.0204 31.7868 92.9837 31.5886 92.0233 31.1922C91.0628 30.7806 90.2243 30.2088 89.5077 29.477C88.7912 28.7452 88.2271 27.899 87.8154 26.9385C87.4038 25.9628 87.198 24.9184 87.198 23.8054C87.198 22.6925 87.4038 21.6557 87.8154 20.6952C88.2271 19.7195 88.7912 18.8733 89.5077 18.1567C90.2243 17.4249 91.0628 16.8532 92.0233 16.4415C92.9837 16.0299 94.0204 15.8241 95.1334 15.8241C96.0176 15.8241 96.8714 15.9765 97.6947 16.2815C98.5179 16.5711 99.2573 16.998 99.9129 17.5621C100.568 18.111 101.087 18.7818 101.468 19.5746L98.9982 20.9697C98.5865 20.2836 98.0529 19.7423 97.3974 19.3459C96.757 18.9343 95.9643 18.7285 95.019 18.7285C94.333 18.7285 93.7079 18.8581 93.1438 19.1172C92.5797 19.3612 92.0842 19.7118 91.6574 20.1692C91.2457 20.6266 90.9179 21.1679 90.674 21.793C90.4453 22.4028 90.331 23.0736 90.331 23.8054C90.331 24.5373 90.4453 25.2157 90.674 25.8408C90.9179 26.4659 91.2457 27.0071 91.6574 27.4645C92.069 27.9067 92.5569 28.2573 93.1209 28.5165C93.7003 28.7757 94.333 28.9053 95.019 28.9053C95.9643 28.9053 96.7647 28.6995 97.4202 28.2878C98.0758 27.8762 98.6018 27.3349 98.9982 26.6641L101.468 28.0591C101.087 28.8367 100.568 29.5075 99.9129 30.0716C99.2573 30.6205 98.5179 31.0474 97.6947 31.3523C96.8714 31.642 96.0176 31.7868 95.1334 31.7868Z"
        fill="white"
      />
      <path
        d="M79.6064 37.0005C78.3105 37.0005 77.1823 36.7261 76.2219 36.1772C75.2614 35.6436 74.522 34.8584 74.0036 33.8217L76.4505 32.4952C76.725 33.0136 77.1213 33.4405 77.6397 33.7759C78.1733 34.1113 78.8136 34.279 79.5607 34.279C80.2467 34.279 80.826 34.119 81.2987 33.7988C81.7865 33.4786 82.1524 33.0365 82.3964 32.4724C82.6555 31.9235 82.7851 31.2832 82.7851 30.5513V28.5846L83.4026 28.2644C83.3111 28.9657 83.1053 29.568 82.7851 30.0711C82.465 30.5742 82.0381 30.963 81.5045 31.2374C80.9861 31.4966 80.3534 31.6262 79.6064 31.6262C78.4172 31.6262 77.4339 31.3899 76.6564 30.9173C75.8788 30.4294 75.2995 29.7585 74.9183 28.9048C74.5524 28.051 74.3695 27.0676 74.3695 25.9546V20.1916H77.2967V25.8403C77.2967 26.7398 77.5254 27.464 77.9827 28.0129C78.4553 28.5617 79.1185 28.8361 79.9723 28.8361C80.5211 28.8361 81.0014 28.7066 81.413 28.4474C81.8246 28.1729 82.1448 27.7994 82.3735 27.3268C82.6174 26.8541 82.7394 26.3282 82.7394 25.7488V20.1916H85.7352V30.5285C85.7352 31.8092 85.476 32.9374 84.9576 33.9131C84.4545 34.8889 83.7456 35.6436 82.8309 36.1772C81.9161 36.7261 80.8413 37.0005 79.6064 37.0005Z"
        fill="white"
      />
      <path d="M69.0338 15.0005H71.9838V31.4892H69.0338V15.0005Z" fill="white" />
      <path
        d="M60.9357 22.7762C60.2801 22.7762 59.7389 22.9363 59.312 23.2565C58.9004 23.5614 58.5878 23.9731 58.3744 24.4914C58.1762 24.9946 58.0771 25.5587 58.0771 26.1838V31.4894H55.1271V25.8407C55.1271 24.743 55.3405 23.7444 55.7674 22.8448C56.2095 21.9453 56.8575 21.2287 57.7112 20.6951C58.565 20.1615 59.6398 19.8947 60.9357 19.8947C62.2316 19.8947 63.314 20.1615 64.183 20.6951C65.052 21.2287 65.7 21.9453 66.1268 22.8448C66.569 23.7291 66.79 24.7277 66.79 25.8407V31.4894H63.8171V26.1838C63.8171 25.5739 63.7104 25.0098 63.497 24.4914C63.2988 23.9731 62.9862 23.5614 62.5593 23.2565C62.1325 22.9363 61.5912 22.7762 60.9357 22.7762Z"
        fill="white"
      />
      <path
        d="M45.9811 31.787C44.8529 31.787 43.801 31.5888 42.8253 31.1924C41.8648 30.7807 41.0186 30.2166 40.2869 29.5C39.5703 28.7835 39.0062 27.9373 38.5946 26.9615C38.1982 25.9858 38 24.9338 38 23.8056C38 22.6774 38.1982 21.633 38.5946 20.6725C39.0062 19.6967 39.5703 18.8506 40.2869 18.134C41.0186 17.4174 41.8648 16.8609 42.8253 16.4645C43.801 16.0529 44.8529 15.8471 45.9811 15.8471C47.1245 15.8471 48.1765 16.0529 49.137 16.4645C50.0975 16.8609 50.936 17.4174 51.6525 18.134C52.3843 18.8506 52.9484 19.6967 53.3448 20.6725C53.7412 21.633 53.9394 22.6774 53.9394 23.8056C53.9394 24.9338 53.7412 25.9858 53.3448 26.9615C52.9484 27.9373 52.3843 28.7835 51.6525 29.5C50.936 30.2166 50.0975 30.7807 49.137 31.1924C48.1765 31.5888 47.1245 31.787 45.9811 31.787ZM45.9811 28.9054C46.6519 28.9054 47.277 28.7758 47.8563 28.5167C48.4509 28.2422 48.9693 27.8763 49.4114 27.4189C49.8535 26.9615 50.1966 26.4279 50.4405 25.8181C50.6844 25.193 50.8064 24.5222 50.8064 23.8056C50.8064 23.089 50.6844 22.4258 50.4405 21.816C50.1966 21.2061 49.8535 20.6725 49.4114 20.2151C48.9693 19.7425 48.4585 19.3766 47.8792 19.1174C47.2999 18.8582 46.6672 18.7286 45.9811 18.7286C45.3103 18.7286 44.6776 18.8582 44.083 19.1174C43.4884 19.3766 42.9701 19.7425 42.528 20.2151C42.1011 20.6725 41.7581 21.2061 41.4989 21.816C41.255 22.4258 41.133 23.089 41.133 23.8056C41.133 24.5222 41.255 25.193 41.4989 25.8181C41.7581 26.4279 42.1011 26.9615 42.528 27.4189C42.9701 27.8763 43.4884 28.2422 44.083 28.5167C44.6776 28.7758 45.3103 28.9054 45.9811 28.9054Z"
        fill="white"
      />
      <g filter="url(#filter0_i_103_22516)">
        <path
          d="M110.445 7.49583C110.308 3.36873 113.543 0.023056 117.673 0.023056L151.835 0.023056C155.965 0.023056 159.424 3.36873 159.562 7.49583V7.49583C159.7 11.6229 156.464 14.9686 152.334 14.9686L118.172 14.9686C114.042 14.9686 110.583 11.6229 110.445 7.49583V7.49583Z"
          fill="url(#paint1_linear_103_22516)"
        />
        <path
          d="M117.69 0.522778L151.852 0.522778C155.705 0.522778 158.933 3.64472 159.062 7.49583C159.19 11.3469 156.171 14.4689 152.318 14.4689L118.155 14.4689C114.302 14.4689 111.074 11.3469 110.945 7.49583C110.817 3.64472 113.836 0.522778 117.69 0.522778Z"
          stroke="url(#paint2_linear_103_22516)"
        />
        <path
          d="M151.567 10.9508C151.113 10.9508 150.713 10.8608 150.367 10.6808C150.027 10.5008 149.763 10.2275 149.577 9.86084L150.337 9.44084C150.463 9.6875 150.623 9.86084 150.817 9.96084C151.017 10.0608 151.253 10.1108 151.527 10.1108C151.72 10.1108 151.89 10.0875 152.037 10.0408C152.183 9.9875 152.3 9.91084 152.387 9.81084C152.473 9.71084 152.517 9.59084 152.517 9.45084C152.517 9.2775 152.447 9.1475 152.307 9.06084C152.173 8.97417 152 8.9075 151.787 8.86084C151.58 8.8075 151.353 8.7575 151.107 8.71084C150.867 8.6575 150.64 8.58084 150.427 8.48084C150.22 8.38084 150.047 8.24084 149.907 8.06084C149.773 7.88084 149.707 7.6375 149.707 7.33084C149.707 7.04417 149.783 6.79084 149.937 6.57084C150.097 6.34417 150.313 6.1675 150.587 6.04084C150.867 5.9075 151.183 5.84084 151.537 5.84084C151.843 5.84084 152.113 5.88417 152.347 5.97084C152.587 6.0575 152.79 6.1775 152.957 6.33084C153.13 6.4775 153.27 6.64417 153.377 6.83084L152.617 7.26084C152.51 7.0475 152.363 6.8975 152.177 6.81084C151.99 6.72417 151.783 6.68084 151.557 6.68084C151.403 6.68084 151.26 6.7075 151.127 6.76084C151 6.8075 150.897 6.8775 150.817 6.97084C150.737 7.06417 150.697 7.1775 150.697 7.31084C150.697 7.47084 150.763 7.59417 150.897 7.68084C151.037 7.7675 151.21 7.8375 151.417 7.89084C151.63 7.94417 151.857 7.9975 152.097 8.05084C152.343 8.10417 152.57 8.18084 152.777 8.28084C152.99 8.37417 153.163 8.51084 153.297 8.69084C153.437 8.87084 153.507 9.1175 153.507 9.43084C153.507 9.7375 153.417 10.0075 153.237 10.2408C153.063 10.4675 152.827 10.6442 152.527 10.7708C152.233 10.8908 151.913 10.9508 151.567 10.9508Z"
          fill="white"
        />
        <path
          d="M147.673 10.8408H146.683L146.683 7.92084C146.683 7.52084 146.757 7.16417 146.903 6.85084C147.057 6.5375 147.277 6.29084 147.563 6.11084C147.857 5.93084 148.217 5.84084 148.643 5.84084C148.803 5.84084 148.957 5.8575 149.103 5.89084C149.257 5.9175 149.397 5.96084 149.523 6.02084L149.523 7.00084C149.403 6.9275 149.273 6.87417 149.133 6.84084C149 6.8075 148.867 6.79084 148.733 6.79084C148.407 6.79084 148.147 6.8975 147.953 7.11084C147.767 7.3175 147.673 7.60084 147.673 7.96084L147.673 10.8408Z"
          fill="white"
        />
        <path
          d="M143.478 10.9608C142.985 10.9608 142.541 10.8442 142.148 10.6108C141.755 10.3775 141.441 10.0675 141.208 9.68083C140.975 9.2875 140.858 8.85417 140.858 8.38083C140.858 7.9075 140.975 7.4775 141.208 7.09083C141.441 6.70416 141.755 6.3975 142.148 6.17083C142.541 5.9375 142.985 5.82083 143.478 5.82083C143.971 5.82083 144.415 5.9375 144.808 6.17083C145.201 6.3975 145.511 6.7075 145.738 7.10083C145.965 7.4875 146.078 7.91416 146.078 8.38083C146.078 8.85417 145.965 9.2875 145.738 9.68083C145.511 10.0675 145.198 10.3775 144.798 10.6108C144.405 10.8442 143.965 10.9608 143.478 10.9608ZM143.478 10.0208C143.785 10.0208 144.058 9.9475 144.298 9.80083C144.545 9.65417 144.738 9.4575 144.878 9.21083C145.018 8.9575 145.088 8.68417 145.088 8.39083C145.088 8.09083 145.018 7.8175 144.878 7.57083C144.738 7.32416 144.545 7.1275 144.298 6.98083C144.058 6.8275 143.785 6.75083 143.478 6.75083C143.171 6.75083 142.895 6.8275 142.648 6.98083C142.408 7.1275 142.215 7.32416 142.068 7.57083C141.921 7.8175 141.848 8.09083 141.848 8.39083C141.848 8.68417 141.921 8.9575 142.068 9.21083C142.215 9.4575 142.408 9.65417 142.648 9.80083C142.895 9.9475 143.171 10.0208 143.478 10.0208Z"
          fill="white"
        />
        <path
          d="M139.503 10.9708C139.103 10.9708 138.763 10.8908 138.483 10.7308C138.203 10.5708 137.99 10.3508 137.843 10.0708C137.703 9.78416 137.633 9.45416 137.633 9.08082L137.633 4.67082H138.623L138.623 9.05082C138.623 9.37082 138.71 9.62082 138.883 9.80082C139.063 9.98082 139.3 10.0708 139.593 10.0708C139.753 10.0708 139.913 10.0442 140.073 9.99082C140.24 9.93749 140.383 9.86749 140.503 9.78082L140.503 10.6908C140.377 10.7708 140.223 10.8375 140.043 10.8908C139.863 10.9442 139.683 10.9708 139.503 10.9708ZM140.503 6.80082H136.663L136.663 5.94082H140.503L140.503 6.80082Z"
          fill="white"
        />
        <path
          d="M133.595 10.9608C133.122 10.9608 132.699 10.8442 132.325 10.6108C131.959 10.3775 131.669 10.0675 131.455 9.68083C131.242 9.2875 131.135 8.85417 131.135 8.38083C131.135 7.91416 131.249 7.4875 131.475 7.10083C131.709 6.7075 132.022 6.3975 132.415 6.17083C132.809 5.9375 133.252 5.82083 133.745 5.82083C134.239 5.82083 134.682 5.93416 135.075 6.16083C135.469 6.38083 135.779 6.68416 136.005 7.07083C136.239 7.4575 136.355 7.89417 136.355 8.38083L136.355 10.8408H135.415L135.415 9.01083L135.755 8.75083C135.755 9.16416 135.662 9.5375 135.475 9.87083C135.295 10.2042 135.042 10.4708 134.715 10.6708C134.395 10.8642 134.022 10.9608 133.595 10.9608ZM133.745 10.0208C134.052 10.0208 134.329 9.9475 134.575 9.80083C134.822 9.65417 135.015 9.4575 135.155 9.21083C135.295 8.9575 135.365 8.68417 135.365 8.39083C135.365 8.09083 135.295 7.8175 135.155 7.57083C135.015 7.32416 134.822 7.1275 134.575 6.98083C134.329 6.8275 134.052 6.75083 133.745 6.75083C133.439 6.75083 133.162 6.8275 132.915 6.98083C132.675 7.1275 132.482 7.32416 132.335 7.57083C132.195 7.8175 132.125 8.09083 132.125 8.39083C132.125 8.68417 132.195 8.9575 132.335 9.21083C132.482 9.4575 132.675 9.65417 132.915 9.80083C133.162 9.9475 133.439 10.0208 133.745 10.0208Z"
          fill="white"
        />
        <path
          d="M128.483 10.9508C127.99 10.9508 127.547 10.8408 127.153 10.6208C126.76 10.3942 126.45 10.0875 126.223 9.70083C125.997 9.30749 125.883 8.86083 125.883 8.36083C125.883 7.89416 125.987 7.46749 126.193 7.08083C126.4 6.68749 126.69 6.37749 127.063 6.15083C127.437 5.91749 127.87 5.80083 128.363 5.80083C128.857 5.80083 129.28 5.91083 129.633 6.13083C129.993 6.34416 130.27 6.63749 130.463 7.01083C130.663 7.37749 130.763 7.78749 130.763 8.24083L130.763 8.64083H126.653L126.833 8.24083C126.813 8.58749 126.87 8.89749 127.003 9.17083C127.137 9.44416 127.33 9.66416 127.583 9.83083C127.837 9.99083 128.133 10.0708 128.473 10.0708C128.78 10.0708 129.057 10.0042 129.303 9.87083C129.557 9.73749 129.757 9.53749 129.903 9.27083L130.663 9.66083C130.45 10.0808 130.153 10.4008 129.773 10.6208C129.393 10.8408 128.963 10.9508 128.483 10.9508ZM126.883 8.00083H129.773C129.76 7.74083 129.69 7.51083 129.563 7.31083C129.443 7.11083 129.277 6.95749 129.063 6.85083C128.857 6.74416 128.62 6.69083 128.353 6.69083C128.08 6.69083 127.837 6.74749 127.623 6.86083C127.417 6.97416 127.25 7.13083 127.123 7.33083C127.003 7.53083 126.923 7.75416 126.883 8.00083Z"
          fill="white"
        />
        <path
          d="M124.109 10.8408H123.119L123.119 7.92084C123.119 7.52084 123.192 7.16417 123.339 6.85084C123.492 6.5375 123.712 6.29084 123.999 6.11084C124.292 5.93084 124.652 5.84084 125.079 5.84084C125.239 5.84084 125.392 5.8575 125.539 5.89084C125.692 5.9175 125.832 5.96084 125.959 6.02084L125.959 7.00084C125.839 6.9275 125.709 6.87417 125.569 6.84084C125.436 6.8075 125.302 6.79084 125.169 6.79084C124.842 6.79084 124.582 6.8975 124.389 7.11084C124.202 7.3175 124.109 7.60084 124.109 7.96084L124.109 10.8408Z"
          fill="white"
        />
        <path
          d="M119.971 10.9608C119.498 10.9608 119.054 10.8742 118.641 10.7008C118.228 10.5208 117.864 10.2742 117.551 9.96083C117.244 9.64083 117.001 9.27083 116.821 8.85083C116.648 8.42416 116.561 7.9675 116.561 7.48083C116.561 7.00083 116.648 6.55083 116.821 6.13083C117.001 5.71083 117.244 5.34416 117.551 5.03083C117.864 4.71083 118.228 4.46416 118.641 4.29083C119.054 4.11083 119.498 4.02083 119.971 4.02083C120.351 4.02083 120.714 4.08416 121.061 4.21083C121.414 4.33083 121.731 4.51083 122.011 4.75083C122.291 4.98416 122.514 5.26416 122.681 5.59083L121.851 6.07083C121.651 5.73083 121.388 5.4675 121.061 5.28083C120.741 5.08749 120.364 4.99083 119.931 4.99083C119.604 4.99083 119.301 5.05416 119.021 5.18083C118.741 5.30749 118.494 5.48416 118.281 5.71083C118.074 5.9375 117.911 6.20416 117.791 6.51083C117.671 6.81083 117.611 7.13416 117.611 7.48083C117.611 7.84083 117.668 8.17416 117.781 8.48083C117.901 8.78749 118.064 9.05416 118.271 9.28083C118.484 9.50083 118.731 9.67416 119.011 9.80083C119.298 9.9275 119.604 9.99083 119.931 9.99083C120.364 9.99083 120.744 9.8975 121.071 9.71083C121.398 9.5175 121.658 9.25416 121.851 8.92083L122.681 9.40083C122.514 9.7275 122.291 10.0075 122.011 10.2408C121.731 10.4742 121.414 10.6542 121.061 10.7808C120.714 10.9008 120.351 10.9608 119.971 10.9608Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_103_22516"
          x="110.196"
          y="0.023056"
          width="49.6153"
          height="14.9455"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.456926 0 0 0 0 0.284375 0 0 0 0 0.83372 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_103_22516" />
        </filter>
        <linearGradient
          id="paint0_linear_103_22516"
          x1="24.2346"
          y1="6.91815"
          x2="24.2346"
          y2="37.9532"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#A377FF" />
          <stop offset="1" stopColor="#48278F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_103_22516"
          x1="134.754"
          y1="0.023056"
          x2="134.754"
          y2="14.9686"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#231540" />
          <stop offset="1" stopColor="#463272" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_103_22516"
          x1="128.497"
          y1="-15.9058"
          x2="149.874"
          y2="37.3441"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#9C80DA" />
          <stop offset="1" stopColor="#5D33B8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoComponent;
