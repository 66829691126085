import styled from 'styled-components';
import { Button } from '../../../components/utils/Button/Button';

export const Content = styled.div`
  padding: 4.4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.4rem;
`;

export const Form = styled.form`
  max-width: 42rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InputsRow = styled.div`
  display: flex;
  gap: 1.6rem;

  > :first-child {
    flex: 1;
  }

  > :nth-child(2) {
    flex: 1;
  }
`;

export const StyledButton = styled(Button)`
  margin-inline: auto;
  margin-top: auto;
`;
