import React from 'react';
import { UPLOAD_FILE_CONSTRAINTS } from '../../utils/constants';
import { notifyError } from '../../utils/notify';
import { ButtonContainer, UploadFileInput } from './UploadMediaButton.styles';
import { Text15Regular } from '../utils/Text/Text.styles';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';

const UploadMediaButton = ({ type, onChange }) => {
  const acceptedExtensionsText = UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS_TEXT;

  const getAudioDuration = file => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(URL.createObjectURL(file));
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = error => {
        reject(error);
      };
    });
  };

  const validateFile = async file => {
    const allowedExtensions = UPLOAD_FILE_CONSTRAINTS[type].ALLOWED_EXTENSIONS;
    const maxFileSizeInBytes = UPLOAD_FILE_CONSTRAINTS[type].MAX_SIZE_IN_BYTES;
    if (file.size < maxFileSizeInBytes) {
      const fileType = file.type.split('/')[1];
      if (allowedExtensions.includes(fileType)) {
        if (type === UPLOAD_FILE_CONSTRAINTS.TYPE.AUDIO) {
          try {
            const audioDuration = await getAudioDuration(file);
            if (audioDuration < 30) {
              notifyError('Audio duration should be at least 30 seconds');
              return;
            }
          } catch (error) {
            notifyError('Unsupported type');
            return;
          }
        }
        return file;
      } else {
        notifyError('Unsupported type');
      }
    } else {
      notifyError('File size error');
    }
  };

  return (
    <ButtonContainer>
      <UploadFileInput
        type="file"
        title=""
        value=""
        multiple
        onChange={async e => {
          const { files: newFiles } = e.target;
          if (newFiles.length) {
            const media = [];
            for (const newFile of [...newFiles]) {
              const file = await validateFile(newFile);

              if (file) {
                media.push({ file, url: URL.createObjectURL(file) });
              }
            }
            if (media.length) {
              onChange(media);
            }
          }
        }}
        accept={acceptedExtensionsText}
      />
      <UploadIcon />
      <Text15Regular>Select</Text15Regular>
    </ButtonContainer>
  );
};

export default UploadMediaButton;
