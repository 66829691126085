import React from 'react';
import { ChartContainer, ChatHeader, Content, PageContainer, StatisticContainer } from './HomePage.styles';
import { Text14Regular, Text18Bold, Text24Bold } from '../../components/utils/Text/Text.styles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user/slice';
import { HomeBarChart, StatisticCard } from '../../components';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet.svg';
import { ReactComponent as VideoRecorderIcon } from '../../assets/icons/video-recorder.svg';
import { ReactComponent as PhoneCallIcon } from '../../assets/icons/phone-call.svg';
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg';
import { VALUE_OF_ONE_CREDIT_IN_USD } from '../../utils/constants';

const HomePage = () => {
  const user = useSelector(selectUser);
  const { earnings, overview, daily } = user.analytics;

  return (
    <PageContainer>
      <Text24Bold>{`Welcome, ${user.name}!`}</Text24Bold>
      <Content>
        <StatisticContainer>
          <StatisticCard
            title="Balance"
            icon={<WalletIcon />}
            value={(earnings.available * VALUE_OF_ONE_CREDIT_IN_USD).toFixed(2)}
            valuePrefix="$"
          />
          <StatisticCard title="Voice Call" icon={<PhoneCallIcon />} value={overview.today.voiceCalls.count} />
          <StatisticCard title="Photos Sold" icon={<CameraIcon />} value={overview.today.photos.count} />
          <StatisticCard title="Videos Sold" icon={<VideoRecorderIcon />} value={overview.today.videos.count} />
        </StatisticContainer>
        <ChartContainer>
          <ChatHeader>
            <Text18Bold>Overview</Text18Bold>
            <Text14Regular>Last 7 Days</Text14Regular>
          </ChatHeader>
          <HomeBarChart dailyAnalytics={daily} />
        </ChartContainer>
      </Content>
    </PageContainer>
  );
};

export default HomePage;
