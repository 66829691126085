import styled from 'styled-components';
import { Text14Light, Text15Light, Text18Regular } from '../../../components/utils/Text/Text.styles';
import { Button } from '../../../components/utils/Button/Button';

export const Content = styled.div`
  padding: 4.4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 500px) {
    height: 100%;
    padding: 2rem 0 3rem 0;
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-inline: 7.6rem;
  margin-top: 6.4rem;

  @media only screen and (max-width: 500px) {
    margin-inline: unset;
  }
`;

export const Title = styled(Text18Regular)`
  text-align: center;
  margin-inline: 8.3rem;

  @media only screen and (max-width: 500px) {
    margin-inline: 2.4rem;
  }
`;

export const Subtitle = styled(Text14Light)`
  line-height: 2.2rem;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
  margin-top: auto;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 28rem;
  margin-top: auto;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const HaveCodeButton = styled(Text15Light)`
  text-decoration: underline;
  cursor: pointer;
  color: #c4a0ff;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  margin-top: 6.4rem;
  flex: 1;
  width: 100%;
`;
