import styled from 'styled-components';

export const UploadFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ButtonContainer = styled.div`
  width: 11.5rem;
  height: 11.5rem;
  background: linear-gradient(180deg, #552b9d 0%, #3b1e70 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  position: relative;
`;
