import React from 'react';
import { useSelector } from 'react-redux';

import { Navigate, Outlet } from 'react-router-dom';
import { FORBIDDEN } from '../App/routes';
import { selectUser } from '../../store/slices/user/slice';

const PrivateOutlet = ({ roles }) => {
  const user = useSelector(selectUser);

  if (!roles.includes(user.role)) {
    return <Navigate to={`/${FORBIDDEN}`} />;
  }

  return <Outlet />;
};

export default PrivateOutlet;
