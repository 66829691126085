import styled from 'styled-components';
import { Text13Light, Text14Regular, Text20Regular } from '../../utils/Text/Text.styles';

export const Card = styled.div`
  height: 13rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 17px;
  border: 1px solid #230e45;
  background: linear-gradient(148deg, rgba(132, 62, 254, 0.2) 2.78%, rgba(0, 0, 0, 0.2) 33.06%),
    linear-gradient(168deg, #130825 16.67%, #090313 95.1%);
  padding: 1.4rem;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const ValueTextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.4rem;
`;

export const PrefixText = styled(Text20Regular)`
  color: #c99aff;
  margin-bottom: 0.5rem;
`;

export const SuffixText = styled(Text14Regular)`
  color: #c99aff;
  margin-bottom: 0.4rem;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

export const BottomText = styled(Text13Light)`
  color: #c99aff;
  letter-spacing: -0.42px;
`;
