import React, { memo } from 'react';
import { CloseWrapper, MediaContainer, ProgressContainer, VoiceContainer } from './MediaComponent.styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { CircularProgressbar } from 'react-circular-progressbar';
import { ReactComponent as VoiceIcon } from '../../assets/icons/voice.svg';
import { Text14Regular } from '../utils/Text/Text.styles';
import { UPLOAD_FILE_CONSTRAINTS } from '../../utils/constants';

const MediaComponent = ({ file, progress, onDelete, type }) => {
  const renderContent = () => {
    switch (type) {
      case UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO:
        return <img src={file.url} alt="influencer-image" />;
      case UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO:
        return <video src={file.url} autoPlay={false} />;
      case UPLOAD_FILE_CONSTRAINTS.TYPE.AUDIO:
        return (
          <VoiceContainer>
            <VoiceIcon />
            <Text14Regular>{file.file ? file.file.name : 'Voice'}</Text14Regular>
          </VoiceContainer>
        );

      default:
        break;
    }
  };
  return (
    <MediaContainer>
      {progress ? (
        <ProgressContainer>
          <CircularProgressbar value={progress} text={`${progress}%`} />
        </ProgressContainer>
      ) : (
        <>
          <CloseWrapper onClick={onDelete}>
            <CloseIcon width={18} height={18} />
          </CloseWrapper>
          {renderContent()}
        </>
      )}
    </MediaContainer>
  );
};

export default memo(MediaComponent);
