import styled from 'styled-components';
import { Text14Light, Text14Regular } from '../../../components/utils/Text/Text.styles';

export const Content = styled.div`
  padding: 4.4rem 0;
  display: flex;
  flex-direction: column;
  margin-inline: 9rem;
  gap: 3.4rem;

  @media only screen and (max-width: 500px) {
    height: 100%;
    padding: 2rem 0 5rem 0;
    margin-inline: 0;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const UploadButton = styled.button`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid #532b9a;
  background: linear-gradient(180deg, #552b9d 0%, #3b1e70 100%);
  height: 4.4rem;
  cursor: pointer;
`;

export const Text = styled(Text14Light)`
  opacity: 0.4;
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`;

export const NumOfMediaText = styled(Text14Regular)`
  color: ${({ $enoughMedia }) => ($enoughMedia ? '#57e140' : '#FF2226')};
`;
