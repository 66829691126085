import React, { useState } from 'react';
import { OnboardingContainer, UploadAudio, UploadPhotos, UploadVideos } from '../../../components';
import { Content, NumOfMediaText, SectionContainer, SectionTitle, Text, UploadButton } from './UploadMediaPage.styles';
import { Text15Regular, Text16Bold } from '../../../components/utils/Text/Text.styles';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/slices/user/slice';

const STEP_MODE = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
};

const UploadMediaPage = () => {
  const user = useSelector(selectUser);
  const [mode, setMode] = useState(null);

  const renderContent = () => {
    switch (mode) {
      case STEP_MODE.PHOTO:
        return <UploadPhotos onBack={() => setMode(null)} />;
      case STEP_MODE.VIDEO:
        return <UploadVideos onBack={() => setMode(null)} />;
      case STEP_MODE.AUDIO:
        return <UploadAudio onBack={() => setMode(null)} />;
      default:
        break;
    }
  };

  return (
    <OnboardingContainer>
      {!mode ? (
        <Content>
          <SectionContainer>
            <SectionTitle>
              <Text16Bold>Photos</Text16Bold>
              <NumOfMediaText $enoughMedia={user.numOfUploadedPhotos >= 50}>
                {user.numOfUploadedPhotos}/50
              </NumOfMediaText>
            </SectionTitle>
            <Text>Upload at least 50 exclusive pictures.</Text>
            <UploadButton onClick={() => setMode(STEP_MODE.PHOTO)}>
              <UploadIcon />
              <Text15Regular>Upload Photos</Text15Regular>
            </UploadButton>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>
              <Text16Bold>Videos</Text16Bold>
              <NumOfMediaText $enoughMedia={user.numOfUploadedVideos >= 10}>
                {user.numOfUploadedVideos}/10
              </NumOfMediaText>
            </SectionTitle>
            <Text>Upload at least 10 videos.</Text>
            <UploadButton onClick={() => setMode(STEP_MODE.VIDEO)}>
              <UploadIcon />
              <Text15Regular>Upload Videos</Text15Regular>
            </UploadButton>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>
              <Text16Bold>Audio</Text16Bold>
              <NumOfMediaText $enoughMedia={user.numOfUploadedAudios >= 1}>{user.numOfUploadedAudios}/1</NumOfMediaText>
            </SectionTitle>
            <Text>
              Upload 30 second audio files to clone your voice. <br />
              Note: No background noise, reading or fast paced speaking.
            </Text>
            <UploadButton onClick={() => setMode(STEP_MODE.AUDIO)}>
              <UploadIcon />
              <Text15Regular>Upload Audio</Text15Regular>
            </UploadButton>
          </SectionContainer>
        </Content>
      ) : (
        <>{renderContent()}</>
      )}
    </OnboardingContainer>
  );
};

export default UploadMediaPage;
