import styled from 'styled-components';

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.8rem;
  opacity: ${props => (props.disabled ? 0.8 : 1)};

  .datePicker {
    font-size: 1.5rem;
    line-height: normal;
    font-weight: 300;
    color: #fff;
    background: linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36;
    height: 5.6rem;
    width: 100%;
    border: 1px solid #2c1655;
    border-radius: 16px;
    padding: 1.4rem 1.6rem;
    box-shadow: none;
    outline: none;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
  }

  .react-datepicker__input-container {
    height: 100%;

    input {
      height: 100%;
      width: 100%;
      font-size: 1.5rem;
      line-height: normal;
      font-weight: 300;
      color: #fff;
      border: none;
      outline: none;
      background: transparent;

      &::placeholder {
        color: #fff;
      }
    }
  }
`;

export const StyledLabelRow = styled.div`
  display: flex;
  align-items: center;
  margin-inline: 0.2rem;
  margin-bottom: 0.6rem;
`;
