import styled from 'styled-components';
import { Text14Light, Text15Light } from '../../../components/utils/Text/Text.styles';
import { Button } from '../../../components/utils/Button/Button';

export const Content = styled.div`
  padding: 4.4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.4rem;
  padding-inline: 8rem;
  overflow: auto;
  max-height: 100%;

  svg {
    min-height: 3.9rem;
  }

  @media only screen and (max-width: 500px) {
    height: 100%;
    padding: 2rem 0 5rem 0;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  flex: 1;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Text = styled(Text14Light)`
  opacity: 0.4;
`;

export const NoteText = styled(Text15Light)`
  line-height: 2.2rem;
  opacity: 0.7;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  border-radius: 16px;
  border: 1px solid #532b9a;
  background: linear-gradient(180deg, #552b9d 0%, #3b1e70 100%);
  padding: 0 1.2rem 0 0.6rem;
  box-shadow: none;
  height: 4.4rem;

  margin-top: auto;
`;
