import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth/slice';
import userSlice from './slices/user/slice';
import influencerRequestSlice from './slices/influencerRequest/slice';
import paymentSlice from './slices/payment/slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    userInfo: userSlice,
    influencerRequestInfo: influencerRequestSlice,
    paymentInfo: paymentSlice,
  },
  devTools: false,
});
