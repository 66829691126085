import styled from 'styled-components';

export const MediaContainer = styled.div`
  width: 11.5rem;
  height: 11.5rem;
  overflow: hidden;
  border: 1px solid #532b9a;
  position: relative;
  display: flex;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const CloseWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 2px;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  background-color: #552b9d;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ProgressContainer = styled.div`
  width: 8rem;
  height: 8rem;
  align-self: center;
  margin: auto;
`;

export const VoiceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding-inline: 1.6rem;
  width: 100%;

  p {
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
