import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const HomeBarChart = ({ dailyAnalytics }) => {
  const data = useMemo(() => {
    const days = Object.keys(dailyAnalytics);

    return days.map(day => {
      const date = parseISO(day);
      // Format the date to get the day of the week
      const dayOfWeek = format(date, 'EEE').toUpperCase();
      return {
        name: dayOfWeek,
        'Audio Calls': dailyAnalytics[day].voiceCalls.count,
        Photos: dailyAnalytics[day].photos.count,
        Videos: dailyAnalytics[day].videos.count,
      };
    });
  }, [dailyAnalytics]);

  const barColors = [
    { leftColor: '#AB75FF', rightColor: '#562C9F' },
    { leftColor: '#6D40BC', rightColor: '#301C52' },
    { leftColor: '#4F2C8B', rightColor: '#3A2263' },
    { leftColor: '#4D2595', rightColor: '#160B2B' },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <CartesianGrid stroke="#1F1632" strokeDasharray="3 3" />
        <defs>
          {barColors.map((color, index) => (
            <linearGradient key={index} id={`colorUv${index}`} x1="0" y1="0" x2="0" y2="100%" spreadMethod="pad">
              <stop offset="0" stopColor={color.leftColor} />
              <stop offset="100%" stopColor={color.rightColor} />
            </linearGradient>
          ))}
        </defs>
        <XAxis dataKey="name" stroke="#1F1632" tick={{ fill: 'white' }} />
        <YAxis stroke="#1F1632" tick={{ fill: 'white' }} />

        <Legend color="white" iconType="circle" />
        <Bar dataKey="Audio Calls" radius={[5, 5, 0, 0]} fill="url(#colorUv0)" />
        <Bar dataKey="Photos" radius={[5, 5, 0, 0]} fill="url(#colorUv1)" />
        <Bar dataKey="Videos" radius={[5, 5, 0, 0]} fill="url(#colorUv2)" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HomeBarChart;
