import styled from 'styled-components';
import { Text14Light } from '../../../components/utils/Text/Text.styles';

export const Content = styled.div`
  padding: 4.4rem 0;
  display: flex;
  flex-direction: column;
  margin-inline: 5rem;
  gap: 1.4rem;
  height: 100%;

  @media only screen and (max-width: 500px) {
    padding: 2rem 0 5rem 0;
    margin-inline: 0;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AgreementContent = styled.div`
  overflow: auto;
`;

export const AgreementText = styled(Text14Light)`
  line-height: 2.2rem;
  opacity: 0.5;
  text-align: justify;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

export const CheckboxWrapper = styled.div`
  cursor: pointer;
`;

export const CheckboxText = styled(Text14Light)`
  line-height: 2.1rem;
  letter-spacing: -0.42px;
`;
