import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 2.4rem 2.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
  flex-grow: 1;
`;

export const StatisticContainer = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const ChartContainer = styled.div`
  border-radius: 17px;
  border: 1px solid #230e45;
  background: linear-gradient(168deg, #130825 16.67%, #090313 95.1%);
  height: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
