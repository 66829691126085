import styled from 'styled-components';
import { Button } from '../utils/Button/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.4rem;
`;

export const Header = styled.div`
  padding: 1.4rem 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackWrapper = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const EmptyDiv = styled.div`
  width: 2.4rem;
`;

export const Content = styled.div`
  display: flex;
  gap: 1px;
  flex-wrap: wrap;
  padding: 2.4rem 0;
  height: 50rem;
  overflow: auto;
  align-content: baseline;
  width: 42rem;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
  width: 42rem;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;
