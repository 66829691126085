import styled from 'styled-components';
import { Text15Regular, Text18Bold } from '../../../components/utils/Text/Text.styles';

export const Content = styled.div`
  padding: 4.4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.4rem;
  padding-inline: 13rem;

  @media only screen and (max-width: 500px) {
    padding: 2rem 0 5rem 0;
    padding-inline: 0;
  }
`;

export const Title = styled(Text18Bold)`
  line-height: 2.6rem;
`;

export const Subtitle = styled(Text15Regular)`
  line-height: 2.2rem;
  text-align: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
