export const LOGOUT = 'LOGOUT';

export const VALUE_OF_ONE_CREDIT_IN_USD = 0.1;
export const CREDITS_FOR_ONE_USD = 1 / VALUE_OF_ONE_CREDIT_IN_USD;

export const VERIFICATION_CODE_TYPES = {
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
};

export const INFLUENCER_REQUEST_STATUS = {
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  SUBSCRIBED: 'SUBSCRIBED',
  IDENTIFIED: 'IDENTIFIED',
  ONBOARDED: 'ONBOARDED',
  MEDIA_UPLOADED: 'MEDIA_UPLOADED',
  COMPLETED: 'COMPLETED',
};

export const PAYMENT_SESSION_STATUS = {
  CREATED: 'CREATED',
  ACTION_REQUIRED: 'ACTION_REQUIRED',
  FAILED: 'FAILED',
  SUCCEEDED: 'SUCCEEDED',
};

export const UPLOAD_FILE_CONSTRAINTS = {
  TYPE: {
    PHOTO: 'PHOTO',
    VIDEO: 'VIDEO',
    AUDIO: 'AUDIO',
  },
  PHOTO: {
    MAX_SIZE_IN_BYTES: 4 * 1024 * 1024, // 4 MB
    ALLOWED_EXTENSIONS: ['png', 'jpeg', 'jpg'],
    ALLOWED_EXTENSIONS_TEXT: '.png, .jpeg, .jpg',
  },
  AUDIO: {
    MAX_SIZE_IN_BYTES: 50 * 1024 * 1024, // 50 MB
    ALLOWED_EXTENSIONS: ['mpeg3', 'x-mpeg-3', 'mpeg', 'm4a', 'x-m4a', 'wav', 'x-wav'],
    ALLOWED_EXTENSIONS_TEXT: '.mp3, .wav, .m4a',
  },
  VIDEO: {
    MAX_SIZE_IN_BYTES: 50 * 1024 * 1024, // 50 MB
    ALLOWED_EXTENSIONS: ['mp4', 'x-mp4'],
    ALLOWED_EXTENSIONS_TEXT: '.mp4',
  },
};

export const IDENTITY_VERIFICATION_STATUS = {
  COMPLETED: 'completed',
};

export const IDENTITY_VERIFICATION_RESULT = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  RED: 'RED',
};

export const USER_ROLE = {
  INFLUENCER: 'INFLUENCER',
  INFLUENCER_REQUEST: 'INFLUENCER_REQUEST',
};
