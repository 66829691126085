import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  padding: 2.4rem 3.2rem;
  width: 18.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #1d1229;

  padding-left: 0;

  @media only screen and (max-width: 1456px) {
    padding-left: 1.6rem;
  }

  @media only screen and (max-width: 768px) {
    display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};
    position: fixed;
    bottom: 2rem;
    left: calc(50% - 10rem);
    top: unset;
    border: none;
    height: 4.8rem;
    z-index: 10;
    padding: unset;
    border-right: unset;
  }
`;

export const NavigationContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    border-radius: 100px;
    border: 1px solid rgba(180, 144, 244, 0.4);
    background: linear-gradient(93deg, rgba(13, 0, 41, 0.62) 0.23%, rgba(16, 3, 41, 0.43) 99.77%), #130c1f;
    width: 20rem;
    flex-direction: row;
    padding: 1.2rem 1.6rem;
    align-items: center;
  }
`;

export const NavigationItem = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
  line-height: normal;
  a {
    display: flex;
    align-items: center;
    height: 2.4rem;
    gap: 1rem;
    color: #ae91ee;
    text-decoration: none;

    svg {
      color: #ae91ee;
    }
  }

  a.active {
    color: #fff;
    svg {
      color: #fff;
    }
  }
`;

export const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

export const NavigationFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLinkText = styled.span`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLinkIconWrapper = styled.div`
  height: 2.7rem;
`;

export const LogoWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const FooterButton = styled.button`
  border-radius: 17px;
  border: 1px solid #230e45;
  background: #130825;
  width: 15.6rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 300;
  padding: 0.6rem 0.6rem 0.6rem 0;
`;
